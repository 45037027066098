import styled from "styled-components";
import backgroundTexture from "./assets/background-texture.svg";

export const Wrapper = styled.div`
  box-sizing: border-box;
  margin: 0 auto;
  padding: 30px 20px;
  max-width: 1200px;
`;

export const Background = styled.div`
  box-sizing: border-box;
  background-image: url(${backgroundTexture}),
    linear-gradient(180deg, #de187b 0%, #e93548 100%);
  background-repeat: no-repeat;

  background-size: 80% 90%, initial;
  background-position: 10px 350px, 0 0;
  height: 100vh;
`;

export const Header = styled.h1`
  font-weight: 900;
  font-size: 48px;
  line-height: 56px;
  text-align: center;

  color: #ffffff;
`;

export const SubHeader = styled.h1`
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 56px;
  text-align: center;
  letter-spacing: -2px;
  color: #ffffff;
`;

export const SubContainer = styled.div`
  box-sizing: border-box;
  background: linear-gradient(180deg, #44096c 0%, #210039 100%);
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25), 0px 0px 24px #df1a78;
  border-radius: 32px;
  min-height: 300px;
  width: 100%;
  padding: 20px 50px;
`;

export const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  width: 150px;
  background: linear-gradient(180deg, #de187b 0%, #e93548 100%);
  border-radius: 50%;
`;
