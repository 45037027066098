import { useState, useEffect } from "react";
import Card from "../Card/Card";
import { SubContainer, Circle, SubHeader } from "../../styles";
import { FiArrowRight } from "react-icons/fi";
import Box from "../Box";
import moviesData from "../../data/moviesData.json";
import gamesData from "../../data/gamesData.json";
import foodsData from "../../data/foodsData.json";

const Slides = (props) => {
  // const [movies, setMovies] = useState();
  const [winners, setWinners] = useState([]);
  const [categoriesToMap, setCategories] = useState([
    moviesData,
    gamesData,
    foodsData,
  ]);

  useEffect(() => {
    if (categoriesToMap?.[0]?.length === 1) {
      setWinners(winners.concat(categoriesToMap?.[0]?.[0]));
      setCategories([...categoriesToMap?.slice(1)]);
    }
  }, [categoriesToMap, winners]);

  const handleOnClick = (i) => {
    if (i === 0) {
      const newMovies = [...categoriesToMap?.[0]];
      newMovies.splice(1, 1);
      setCategories([newMovies, ...categoriesToMap?.slice(1)]);
    } else if (i === 1) {
      const newMovies = [...categoriesToMap?.[0]];
      newMovies.splice(0, 1);
      setCategories([newMovies, ...categoriesToMap?.slice(1)]);
    }
  };

  return (
    <SubContainer>
      <SubHeader> What do you feel like?</SubHeader>
      <Box display='flex' flexDirection='row' justifyContent='space-around'>
        {categoriesToMap[0]?.slice(0, 2)?.map((item, i) => (
          <Box onClick={() => handleOnClick(i)}>
            <Card
              thumbnail={item?.image}
              title={item?.title}
              company={item?.company}
              releaseDate={item?.release}
              sentiment={item?.sentimentality}
              recentTweets='40'
              totalTweets={item?.tweets}
            />
          </Box>
        ))}
        {categoriesToMap?.length === 0 && (
          <Box>
            <SubHeader>Great choices, sounds like a plan</SubHeader>
            <Box
              display='flex'
              flexDirection='row'
              justifyContent='space-between'
            >
              {winners?.map((winner) => (
                <Card
                  thumbnail={winner?.image}
                  title={winner?.title}
                  company={winner?.company}
                  releaseDate={winner?.release}
                  sentiment={winner?.sentimentality}
                  recentTweets='40'
                  totalTweets={winner?.tweets}
                />
              ))}
            </Box>
          </Box>
        )}
      </Box>
      <Box mt='50px' display='flex' justifyContent='center'>
        <Circle>
          <FiArrowRight color='white' size={90} />
        </Circle>
      </Box>
    </SubContainer>
  );
};

Slides.propTypes = {};

export default Slides;
