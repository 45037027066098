import React from "react";
import styled from "styled-components";
import Box from "../Box";

const Img = styled.img`
  /* width: 100%;
  height: auto; */
  cursor: pointer;
`;

const Span = styled.span`
  display: block;
`;

const Sentiment = ({ sentiment }) => {
  const percent = Math.floor(sentiment * 100);

  return (
    <>
      <Box mt='32px' position='relative' width='100%' height='16px'>
        <Box
          position='absolute'
          width='100%'
          height='100%'
          top='0'
          bottom='0'
          right='0'
          left='0'
          bg='lightgray'
        />
        <Box
          position='absolute'
          width={`${percent}%`}
          height='100%'
          top='0'
          bottom='0'
          right='0'
          left='0'
          bg='#42be2d'
        />
      </Box>
      <Box mt='8px'>{`${percent}%`} Positive Sentiment</Box>
    </>
  );
};

const Card = ({
  type,
  thumbnail,
  title,
  company,
  releaseDate,
  sentiment,
  recentTweets,
  totalTweets,
}) => {
  return (
    <Box
      bg='#FFFFFF'
      boxShadow='0px 4px 4px rgba(0, 0, 0, 0.25), 0px 0px 32px rgba(0, 0, 0, 0.25)'
      borderRadius='32px'
      p='32px 32px 16px'
      textAlign='center'
    >
      <Img src={thumbnail} alt={title} />

      <Box mt='16px'>
        <Span>{title}</Span>
        {(type === "movie" || type === "game") && (
          <>
            <Span>{company}</Span>
            <Span>{releaseDate}</Span>
          </>
        )}
      </Box>

      <Sentiment sentiment={sentiment} />

      <Box mt='32px'>
        {recentTweets && <Span>{recentTweets} Recent Tweets</Span>}
        {totalTweets && <Span>{totalTweets} Total Tweets</Span>}
      </Box>
    </Box>
  );
};

export default Card;
