import React from 'react'
import styled, { keyframes, css } from 'styled-components'
import {
  space,
  position,
  color,
  typography,
  border,
  layout,
  flexbox,
  grid,
  variant,
  shadow,
  background,
} from 'styled-system'

const zoomIn = keyframes`

0% {
  transform: scale(0);
}
50% {
  transform: translate(-50%, -50%) scale(0);
}
100% {
  transform: translate(-50%, -50%) scale(1);
}
`

const zoomOut = keyframes`
0% {
  transform: translate(-50%, -50%) scale(1);
}
100% {
  transform: translate(-50%, -50%) scale(0);
}
`

const animationStyles = css`
  animation: ${({ isMounted }) =>
    isMounted
      ? css`
          ${zoomIn} 1.5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards
        `
      : css`
          ${zoomOut} .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards
        `};
`

export const StyledBox = styled.div`
  box-sizing: border-box;
  ${position}
  ${flexbox}
  ${grid}
  ${space}
  ${color}
  ${layout}
  ${typography}
  ${border}
  ${shadow}
  ${background}
  ${variant}
  ${({ filter, transform, cursor, textTransform }) => ({
    filter,
    transform,
    cursor,
    textTransform,
  })}
  
  ${({ fadeIn }) => fadeIn && animationStyles}
  ${({ pointerEvents }) => pointerEvents && `pointer-events: ${pointerEvents};`}
  ${({ maskImage }) => maskImage && `mask-image: ${maskImage};`}
  ${({ maskPosition }) =>
    maskPosition &&
    `mask-position: ${maskPosition}; -webkit-mask-position: ${maskPosition};`}
  ${({ maskSize }) =>
    maskSize && `mask-size: ${maskSize}; -webkit-mask-size: ${maskSize};`}
  ${({ clipPath }) => clipPath && `clip-path: ${clipPath};`}

  // remove tab and click interactions on non inFrame/active slide buttons
  ${({ removeInteraction }) =>
    removeInteraction &&
    `
    pointer-events: none;
    [role="button"]{
    display: none !important; 
  }`}
`

const Box = React.forwardRef(({ children, ...props }, ref) => {
  return (
    <StyledBox ref={ref} {...props}>
      {children}
    </StyledBox>
  )
})

Box.displayName = 'Box'

export default Box
export { Box }
