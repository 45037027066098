import IntroSlide from "./components/Slides/Slides";
import { Wrapper, Header, Background } from "./styles";

function App() {
  return (
    <Background>
      <Wrapper>
        <Header>Have a Trendy Weekend!</Header>
        <IntroSlide />
      </Wrapper>
    </Background>
  );
}

export default App;
